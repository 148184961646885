<!--
 * @Description: 项目动态-正文
 * @Author: Chp
 * @Date: 2021-04-02 15:54:01
 * @LastEditTime: 2021-06-18 16:12:47
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="pro-article">
    <ArticleTitle :info="detail" source="Project"></ArticleTitle>
    <RichArticle :html="detail.contentDetails"></RichArticle>
    <template v-if="list.length > 0">
      <LogoTitle msg="项目动态"></LogoTitle>
      <div class="list">
        <NewsCard
          source="Project"
          v-for="item in list"
          :info="item"
          :key="item.id"
          @tagClick="tagClick"
        ></NewsCard>
      </div>
      <div class="sljr-pagination">
        <SinglePagination
          :total="total"
          v-if="total <= pageSize"
        ></SinglePagination>
        <el-pagination
          :hide-on-single-page="isHide"
          :page-size="pageSize"
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentPage: 1,//当前页数
      pageSize: 5,//每页显示条目个数
      total: 0,//总条目数
      isHide: true,//只有一页时是否隐藏
      detail: '',//详情
      id: '',
      list: []
    }
  },
  created () {
    this.init();
  },
  methods: {
    init () {
      this.id = this.$route.query.id;
      this.getDetail();
    },
    /**
   * @description: 获取详情
   * @param  {id:this.id}
   * @return {*}
   */
    getDetail () {
      let params = {
        id: this.id
      }
      this.$api.getDetail(params).then((res) => {
        this.detail = res.data.data.info;
        document.title = this.detail.contentTitle;
        this.getList();
      })
    },
    /**
     * @description: 项目动态列表
     */
    getList () {
      let params = {
        id: this.detail.id,
        categoryId: this.detail.categoryId
      }
      this.$api.getContentTagsList(params).then(res => {
        this.list = res.data.data.rows;
        this.total = res.data.data.total
      })
    },
    tagClick (data) {
      this.tagsId = data;
      this.currentPage = 1;
      this.getFilterList();
    },
    //标签筛选
    getFilterList () {
      let params = {
        categoryId: this.detail.categoryId,
        categoryType: '1',
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        tagsId: this.tagsId
      }
      this.$api.getContentList(params).then((res) => {
        this.total = res.data.data.total
        this.list = res.data.data.rows
      })
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
    }
  },
  watch: {
    '$route.query.id' () {
      this.init()
    }
  }
}
</script>

<style scoped lang='scss'>
</style>